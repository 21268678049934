<template>
  <v-container fluid>
    <v-row>
      <upload
        v-bind:path="additionalInfoPath"
        v-on:uploadComplete="additionalInfoUploaded"
        button-text="Upload"
      ></upload>
      <v-col cols="12">
        <v-list>
          <v-list-item v-for="(doc,index) in documents" :key="index">
            <v-list-item-content>
              <a v-bind:href="doc.downloadURL" target="_blank">{{doc.filename}}</a>
               <v-icon small @click="deleteItem(doc)"  >mdi-delete</v-icon>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="6"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);

export default {
  name: "goals",

  data: () => ({
    vm: {},
    dialog: false,
    valid: true,
    documents: [],
    totalItems: 0,
    loading: false,
    additionalInfoPath: "",
    queryFilters: [],
    rowPageConfig: [
      50,
      100,
      200,
      { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 }
    ]
  }),
  props: {
    msg: String
  },
  mounted() {
    
    let basePath =
      this.currentOrganisation.id + "/" + this.currentCase.id + "/";
    this.additionalInfoPath = basePath + "additionalInfo/";
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentGoal(state) {
      return state.currentGoal;
    }
  },
  methods: {
    init() {
      this.vm = {};
      this.currentCase.additionalInfo.forEach(doc => {
        if(this.$utils.isEmpty(doc.isDeleted) || !doc.isDeleted ){
          this.documents.push(doc);
        }
      });
    },
    deleteItem(item) {
     
      const index = this.documents.indexOf(item);
      if (confirm("Are you sure you want to delete this item?")) {
        item.isDeleted =true;
        this.documents.splice(index, 1);
        this.$store.dispatch("setCurrentCase", this.currentCase);
      }
    },
    additionalInfoUploaded(data) {
      this.currentCase.isAdditionalInfoUploaded = true;

      if (
        this.currentCase.additionalInfo == undefined ||
        !Array.isArray(this.currentCase.additionalInfo)
      ) {
        this.currentCase.additionalInfo = [];
      }
      this.currentCase.additionalInfo.push(data);
      this.documents.push(data);
      this.$store.dispatch("setCurrentCase", this.currentCase);
      this.$forceUpdate();
    },
  
    next() {
      this.$emit("next-step");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>


